<template>
  <div>
    <b-card
      no-body
      style="padding-bottom: 8px; padding-top: 10px"
      class="card-body"
    >
      <b-card-header
        style="font-size: 1.1em"
        class="d-flex justify-content-center"
      >
        <h4>{{ $t("Devices and Results") }}</h4>
      </b-card-header>
      <b-row class="list-rows">
        <b-col>
          <p class="device-props row">
            <b-col cols="" class="left-prop-column">L101</b-col>
            <b-col>
              <b-badge variant="warning"
                ><h5 style="color: white">{{ $t("Loose Screw") }}</h5></b-badge
              >
            </b-col>
            <b-col cols="" class="right-prop-column">
              {{ $t("Last Updated") }} : {{ getDate }}
            </b-col>
          </p>
        </b-col>
      </b-row>
      <b-row class="list-rows">
        <b-col>
          <p class="device-props row">
            <b-col cols="" class="left-prop-column">L102</b-col>
            <b-col>
              <b-badge variant="success"
                ><h5 style="color: white">{{ $t("Healthy") }}</h5></b-badge
              >
            </b-col>
            <b-col cols="" class="right-prop-column">
              {{ $t("Last Updated") }} : {{ getDate }}
            </b-col>
          </p>
        </b-col>
      </b-row>
      <b-row class="list-rows">
        <b-col>
          <p class="device-props row">
            <b-col cols="" class="left-prop-column">L103</b-col>
            <b-col>
              <b-badge variant="success"
                ><h5 style="color: white">{{ $t("Healthy") }}</h5></b-badge
              >
            </b-col>
            <b-col cols="" class="right-prop-column">
              {{ $t("Last Updated") }} : {{ getDate }}
            </b-col>
          </p>
        </b-col>
      </b-row>
      <b-row class="list-rows">
        <b-col>
          <p class="device-props row">
            <b-col cols="" class="left-prop-column">L104</b-col>
            <b-col>
              <b-badge variant="warning"
                ><h5 style="color: white">
                  {{ $t("Maintenance Required") }}
                </h5></b-badge
              >
            </b-col>
            <b-col cols="" class="right-prop-column">
              {{ $t("Last Updated") }} : {{ getDate }}
            </b-col>
          </p>
        </b-col>
      </b-row>
      <b-row class="list-rows">
        <b-col>
          <p class="device-props row">
            <b-col cols="" class="left-prop-column">L105</b-col>
            <b-col>
              <b-badge variant="success"
                ><h5 style="color: white">{{ $t("Healthy") }}</h5></b-badge
              >
            </b-col>
            <b-col cols="" class="right-prop-column">
              {{ $t("Last Updated") }} : {{ getDate }}
            </b-col>
          </p>
        </b-col>
      </b-row>
      <b-row class="list-rows">
        <b-col>
          <p class="device-props row">
            <b-col cols="" class="left-prop-column">L106</b-col>
            <b-col>
              <b-badge variant="success"
                ><h5 style="color: white">{{ $t("Healthy") }}</h5></b-badge
              >
            </b-col>
            <b-col cols="" class="right-prop-column">
              {{ $t("Last Updated") }} : {{ getDate }}
            </b-col>
          </p>
        </b-col>
      </b-row>
      <b-row class="list-rows">
        <b-col>
          <p class="device-props row">
            <b-col cols="" class="left-prop-column">L107</b-col>
            <b-col>
              <b-badge variant="warning"
                ><h5 style="color: white">{{ $t("Loose Screw") }}</h5></b-badge
              >
            </b-col>
            <b-col cols="" class="right-prop-column">
              {{ $t("Last Updated") }} : {{ getDate }}
            </b-col>
          </p>
        </b-col>
      </b-row>
      <b-row class="list-rows">
        <b-col>
          <p class="device-props row">
            <b-col cols="" class="left-prop-column">L108</b-col>
            <b-col>
              <b-badge variant="success"
                ><h5 style="color: white">{{ $t("Healthy") }}</h5></b-badge
              >
            </b-col>
            <b-col cols="" class="right-prop-column">
              {{ $t("Last Updated") }} : {{ getDate }}
            </b-col>
          </p>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { BCard, BRow, BCol, BBadge, BCardHeader } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BCardHeader,
  },
  props: {
    data: [],
  },
  computed: {
    getDate() {
      const d = new Date();
      return new Intl.DateTimeFormat("default", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(d);
    },

    getCardColor() {
      if (this.data.rightValue < 50) {
        return "red";
      } else if (this.data.rightValue <= this.data.efficiencyLimit) {
        return "orange";
      } else {
        return "green";
      }
    },
  },
};
</script>
<style>
.device-props {
  margin-left: auto;
  text-align: justify;
  margin-bottom: clamp(2.5px, 1vw, 4px);
  margin-top: clamp(2.5px, 1vw, 4px);
  font-size: Clamp(1em, 1.8vw, 1.2em);
  color: black;
}

.left-prop-column {
  font-weight: 400;
}

.right-prop-column {
  margin-left: 1em;
}

.list-rows:not(:last-child) {
  border-bottom: 2px solid rgba(122, 122, 122, 0.1);
}

.device-props.row {
  align-items: center;
}
</style>
